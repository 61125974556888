import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import PersonalWebsite from './PersonalWebsite';
import WritingPage from './WritingPage';
import ArticlePage from './ArticlePage';
import RecommendedReading from './RecommendedReading';
import ProjectsPage from './ProjectsPage';
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  const [darkMode, setDarkMode] = useState(() => {
    // Always default to dark mode on first load
    const savedMode = localStorage.getItem('darkMode');
    return savedMode !== null ? JSON.parse(savedMode) : true;
  });

  useEffect(() => {
    localStorage.setItem('darkMode', JSON.stringify(darkMode));

    // Apply or remove the 'dark' class to the body element
    if (darkMode) {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [darkMode]);

  const toggleDarkMode = () => setDarkMode(prevMode => !prevMode);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<PersonalWebsite darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
        <Route path="/writings" element={<WritingPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
        <Route path="/writings/:filename" element={<ArticlePage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
        <Route path="/recommended-reading" element={<RecommendedReading darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
        <Route path="/projects" element={<ProjectsPage darkMode={darkMode} toggleDarkMode={toggleDarkMode} />} />
      </Routes>
      <Analytics />
      <SpeedInsights />
    </Router>
  );
}

export default App;
