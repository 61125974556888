import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Sun, Moon, ExternalLink, ChevronDown, ChevronUp } from 'lucide-react';

const RecommendedItem = ({ number, title, link, notes, darkMode }) => {
  const [showNotes, setShowNotes] = useState(false);

  return (
    <li className="mb-6 flex">
      <span className="mr-4 font-bold">{number}.</span>
      <div>
        <h2 className="text-xl font-semibold">{title}</h2>
        <a 
          href={link} 
          target="_blank" 
          rel="noopener noreferrer" 
          className={`inline-flex items-center ${darkMode ? 'text-[#50f08d]' : 'text-[#463730]'} hover:underline mt-2`}
        >
          Read <ExternalLink size={16} className="ml-1" />
        </a>
        {notes && (
          <div className="mt-2">
            <button
              onClick={() => setShowNotes(!showNotes)}
              className={`flex items-center ${darkMode ? 'text-[#40e07d]' : 'text-[#463730]'} hover:underline`}
            >
              {showNotes ? 'Hide' : 'Show'} Notes
              {showNotes ? <ChevronUp size={16} className="ml-1" /> : <ChevronDown size={16} className="ml-1" />}
            </button>
            {showNotes && <p className="mt-2 text-sm">{notes}</p>}
          </div>
        )}
      </div>
    </li>
  );
};

const RecommendedReading = ({ darkMode, toggleDarkMode }) => {
  const recommendations = [
    {
      title: "Best practices in machine learning for chemistry",
      link: "https://www.nature.com/articles/s41557-021-00716-z",
      notes: "Need to read"
    },
    // Add more recommendations here
  ];

  return (
    <div className={`min-h-screen font-mono p-8 transition-colors duration-300 ${
      darkMode ? 'bg-[#081118] text-[#40e07d]' : 'bg-[#f5f5dc] text-[#463730]'
    }`}>
      <div className="max-w-3xl mx-auto px-4 pt-8">
        {/* Flex container to align heading and button */}
        <div className="flex justify-between items-center mb-4">
          {/* Heading aligned to the left */}
          <h1 className="text-3xl font-bold text-left">Recommended Reading</h1>

          {/* Dark mode toggle aligned with the heading */}
          <button 
            onClick={toggleDarkMode} 
            className={`p-2 rounded-full transition-colors duration-300 ${
              darkMode 
                ? 'bg-[#40e07d] text-[#081118] hover:bg-[#50f08d]' 
                : 'bg-[#463730] text-[#f5f5dc] hover:bg-[#34495e]'
            }`}
            aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            {darkMode ? <Moon size={24} /> : <Sun size={24} />}
          </button>
        </div>

        {/* Back to Home link, with the arrow not underlined */}
        <Link 
          to="/" 
          className={`text-lg transition-colors duration-300 ${
            darkMode 
              ? 'text-[#40e07d] hover:text-[#50f08d]'  // Green in dark mode
              : 'text-[#463730] hover:text-[#008080]'  // Brown with teal hover in light mode
          }`}
        >
          <span className="no-underline">←</span>
          <span className="ml-2 underline">Back to Home</span>
        </Link>

        {/* Recommended Reading List */}
        <ul className="mt-8 space-y-8">
          {recommendations.map((item, index) => (
            <RecommendedItem 
              key={index}
              number={index + 1}
              title={item.title}
              link={item.link}
              notes={item.notes}
              darkMode={darkMode}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default RecommendedReading;
