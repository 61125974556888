import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Sun, Moon } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import 'katex/dist/katex.min.css';

const ArticlePage = ({ darkMode, toggleDarkMode }) => {
  const { filename } = useParams();
  const [article, setArticle] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/content/${filename}.md`)
      .then(response => response.text())
      .then(text => {
        setArticle(text);
        setIsLoading(false);
      });
  }, [filename]);

  useEffect(() => {
    document.body.style.backgroundColor = darkMode ? '#000000' : '#F5F5DC';
    return () => {
      document.body.style.backgroundColor = '';
    };
  }, [darkMode]);

  if (isLoading) {
    return (
      <div className={`min-h-screen font-mono p-8 flex items-center justify-center ${
        darkMode ? 'bg-black text-white' : 'bg-[#f5f5dc] text-[#463730]'
      }`}>
        Loading...
      </div>
    );
  }

  return (
    <div className={`relative min-h-screen font-mono transition-colors duration-300 ${
      darkMode ? 'bg-black text-white' : 'bg-[#f5f5dc] text-[#463730]'
    }`}>
      <div className="max-w-3xl mx-auto px-4 pt-8 flex-grow">
        {/* Header section: Back to Writings link and Dark Mode toggle */}
        <div className="flex items-center justify-between mb-6">
          <Link 
            to="/writings" 
            className={`text-lg font-bold transition-colors duration-300 ${
              darkMode 
                ? 'text-white hover:text-gray-300'
                : 'text-[#463730] hover:text-[#008080]'
            }`}
          >
            <span className="no-underline">←</span>
            <span className="ml-2 underline">Back to Writings</span>
          </Link>

          <button 
            onClick={toggleDarkMode} 
            className={`p-2 rounded-full transition-colors duration-300 ${
              darkMode 
                ? 'bg-white text-black hover:bg-gray-500'
                : 'bg-[#463730] text-[#f5f5dc] hover:bg-[#34495e]'
            }`}
            aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
          >
            {darkMode ? <Sun size={24} /> : <Moon size={24} />}
          </button>
        </div>

        {/* Article Content */}
        <article className="prose max-w-none">
          <ReactMarkdown
            remarkPlugins={[remarkMath]}
            rehypePlugins={[rehypeKatex]}
            components={{
              h1: ({node, children, ...props}) => <h1 className={`text-3xl font-bold mb-4 ${darkMode ? 'text-white' : 'text-[#463730]'}`} {...props}>{children}</h1>,
              h2: ({node, children, ...props}) => <h2 className={`text-2xl font-bold mb-3 ${darkMode ? 'text-white' : 'text-[#463730]'}`} {...props}>{children}</h2>,
              h3: ({node, children, ...props}) => <h3 className={`text-xl font-bold mb-2 ${darkMode ? 'text-white' : 'text-[#463730]'}`} {...props}>{children}</h3>,
              p: ({node, children, ...props}) => <p className={`mb-4 ${darkMode ? 'text-white' : 'text-[#463730]'}`} {...props}>{children}</p>,
              ul: ({node, children, ...props}) => <ul className={`list-disc list-inside mb-4 ${darkMode ? 'text-white' : 'text-[#463730]'}`} {...props}>{children}</ul>,
              ol: ({node, children, ...props}) => <ol className={`list-decimal list-inside mb-4 ${darkMode ? 'text-white' : 'text-[#463730]'}`} {...props}>{children}</ol>,
              li: ({node, children, ...props}) => <li className={`mb-1 ${darkMode ? 'text-white' : 'text-[#463730]'}`} {...props}>{children}</li>,
              a: ({node, children, ...props}) => <a className={`text-[#463730] hover:text-[#008080] ${darkMode ? 'text-[#40e07d] hover:text-[#50f08d]' : ''}`} {...props}>{children}</a>,
              blockquote: ({node, children, ...props}) => <blockquote className={`border-l-4 pl-4 italic my-4 ${darkMode ? 'text-[#40e07d]' : 'text-[#463730]'}`} {...props}>{children}</blockquote>,
              code: ({node, inline, className, children, ...props}) => {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    style={tomorrow}
                    language={match[1]}
                    PreTag="div"
                    className="rounded-md overflow-hidden"
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code className={`bg-gray-200 rounded px-1 ${darkMode ? 'bg-gray-800' : ''} ${className}`} {...props}>
                    {children}
                  </code>
                );
              },
            }}
          >
            {article}
          </ReactMarkdown>
        </article>
      </div>

    </div>
  );
};

export default ArticlePage;
