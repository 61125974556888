import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Sun, Moon } from 'lucide-react';

const ArticleComponent = ({ filename, title, content, date, darkMode, isFirst }) => {
  const [year, month, day] = date.split('-');
  const formattedDate = `${day}${month}${year}`; // DDMMYYYY format

  return (
    <div className={`mb-6 ${isFirst ? 'pt-4' : ''}`}> {/* Add padding-top if it's the first article */}
      <div className="flex items-center">
        <span className={`text-lg font-bold ${darkMode ? 'text-[#40e07d]' : 'text-[#463730]'}`}>
          {formattedDate}
        </span>
        <span className="ml-2 text-lg font-bold">-</span>
        <Link
          to={`/writings/${filename.replace('.md', '')}`}
          className={`ml-2 text-lg font-bold underline transition-colors duration-300 ${
            darkMode 
              ? 'text-white hover:text-gray-300'
              : 'text-[#463730] hover:text-[#008080]'
          }`}
        >
          {title}
        </Link>
      </div>
    </div>
  );
};

const WritingPage = ({ darkMode, toggleDarkMode }) => {
  const [writings, setWritings] = useState([]);

  useEffect(() => {
    const fetchArticlePreviews = async () => {
      try {
        const response = await fetch('/articles.json');
        const files = await response.json();

        const articlesWithContent = await Promise.all(
          files.map(async (filename) => {
            const response = await fetch(`/content/${filename}`);
            const text = await response.text();
            const lines = text.split('\n');
            const title = lines[0].replace('# ', '');
            const date = lines[1].replace('Date: ', ''); // Second line is the date
            const preview = lines.slice(2, 4).join('\n');
            return { filename, title, date, content: preview };
          })
        );

        articlesWithContent.sort((a, b) => new Date(b.date) - new Date(a.date));
        setWritings(articlesWithContent);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticlePreviews();
  }, []);

  return (
    <div className={`relative min-h-screen font-mono transition-colors duration-300 ${
      darkMode ? 'bg-black text-white' : 'bg-[#f5f5dc] text-[#463730]'
    }`}>
      <div className="max-w-3xl mx-auto p-4 flex flex-col min-h-screen">
        <header className="mb-6"> {/* Adjust margin-bottom */}
          <div className="flex items-center justify-between mb-4"> {/* Adjusted margin */}
            {/* Heading aligned to the left */}
            <h1 className={`text-3xl font-bold ${darkMode ? 'text-white' : 'text-[#463730]'}`}>
              My Writings
            </h1>
            {/* Dark mode toggle aligned with the heading */}
            <button
              onClick={toggleDarkMode}
              className={`p-2 rounded-full transition-colors duration-300 ${
                darkMode
                  ? 'bg-white text-black hover:bg-gray-500'
                  : 'bg-[#463730] text-[#f5f5dc] hover:bg-[#34495e]'
              }`}
              aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              {darkMode ? <Sun size={24} /> : <Moon size={24} />} {/* Updated icon size */}
            </button>
          </div>

          <Link
            to="/"
            className={`text-lg font-bold transition-colors duration-300 ${
              darkMode
                ? 'text-white hover:text-gray-300'
                : 'text-[#463730] hover:text-[#008080]'
            }`}
          >
            <span className="no-underline">←</span>
            <span className="ml-1 underline">Back to Home</span> {/* Reduced margin-left */}
          </Link>
        </header>

        <main className="flex-grow">
          <div className="space-y-6"> {/* Adjusted space between articles */}
            {writings.map((writing, index) => (
              <ArticleComponent
                key={writing.filename}
                {...writing}
                darkMode={darkMode}
                isFirst={index === 0} // Pass isFirst prop
              />
            ))}
          </div>
        </main>
      </div>

      <footer className={`absolute bottom-0 w-full border-t ${
        darkMode ? 'border-gray-500' : 'border-[#463730]/30'
      }`}>
        <div className="max-w-3xl mx-auto p-2"> {/* Reduced padding */}
          <p className={`text-sm text-center ${darkMode ? 'text-gray-500' : 'text-[#463730]/70'}`}>
            algorithms and caffeine | MEBGrad
          </p>
        </div>
      </footer>
    </div>
  );
};

export default WritingPage;
