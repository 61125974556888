import { Sun, Moon } from 'lucide-react';
import { Link } from 'react-router-dom';

const ASCII_ART = `
                                    ████                                    
                                  ██    ██                                  
                                ██        ██                                
                              ██            ██                              
                              ██            ██                              
                              ██            ██                              
      ████                  ██                ██                            
    ████████                ██                ██                ████████    
  ██  ████  ████            ██                ██            ████        ██  
██              ████      ██                    ██      ████              ██
██                  ████  ██                    ██  ████                  ██
██                      ████                    ████                      ██
██                        ██████            ██████                  ████  ██
  ██                    ██      ████    ████      ██              ████████  
    ██                  ██          ████          ██              ████████  
      ██                ██      ████    ████      ██                ████    
        ████            ██  ████            ████  ██            ████        
            ████        ████        ████        ████        ████            
                ██████████        ████████        ██████████                
                ██████████        ████████        ██████████                
            ████        ████        ████        ████        ████            
        ████            ██  ████            ████  ██            ████        
      ██                ██      ████    ████      ██                ██      
    ██                  ██          ████          ██                  ██    
  ██                    ██      ████    ████      ██                    ██  
██                        ██████            ██████                        ██
██                      ████                    ████                      ██
██                  ████  ██                    ██  ████                  ██
██              ████      ██                    ██      ████              ██
  ██        ████            ██                ██            ████        ██  
    ████████                ██                ██                ████████    
                            ████              ██                            
                              ██▓▓          ▓▓░░                            
                            ████████        ██                              
                            ████████        ██                              
                              ████        ██                                
                                  ██    ██                                  
                                    ████                                    
`;

const Header = ({ title, subtitle, darkMode }) => (
  <div className="mb-6">
    <h2 className={`text-2xl font-bold mb-2 ${darkMode ? 'text-white' : 'text-[#463730]'}`}>{title}</h2>
    <p className={`text-sm ${darkMode ? 'text-gray-400' : 'text-[#34495e]'}`}>{subtitle}</p>
  </div>
);

const Section = ({ title, subtitle, children, darkMode }) => (
  <section className="mb-8">
    <Header title={title} subtitle={subtitle} darkMode={darkMode} />
    {children}
  </section>
);



const PersonalWebsite = ({ darkMode, toggleDarkMode }) => {
  

   return (
    <div className={`min-h-screen font-mono transition-colors duration-300 ${
      darkMode ? 'bg-black text-white' : 'bg-[#f5f5dc] text-[#463730]'
    }`}>
      <div className="max-w-3xl mx-auto p-4">
        <header className="mb-12">
          <div className="flex justify-between items-center mb-4">
            <h1 className={`text-4xl font-bold ${darkMode ? 'text-white' : 'text-[#463730]'}`}>MEBGrad</h1>
            <button 
              onClick={toggleDarkMode} 
              className={`p-2 rounded-full transition-colors duration-300 ${
                darkMode 
                  ? 'bg-white text-black hover:bg-gray-500' 
                  : 'bg-[#463730] text-[#f5f5dc] hover:bg-[#34495e]'
              }`}
              aria-label={darkMode ? "Switch to light mode" : "Switch to dark mode"}
            >
              {darkMode ? <Sun size={24} /> : <Moon size={24} />}
            </button>
          </div>
          <p className={`text-xl mb-4 text-center font-bold ${darkMode ? 'text-white' : 'text-[#463730]'}`}>
            PhD Student & ML Enthusiast 
          </p>
          <div className="flex justify-center items-center space-x-4">
            <Link to="/writings" className={`underline text-lg font-bold ${darkMode ? 'text-white hover:text-gray-300' : 'text-[#463730] hover:text-[#008080]'}`}>My Writings</Link>
            <Link to="/projects" className={`underline text-lg font-bold ${darkMode ? 'text-white hover:text-gray-300' : 'text-[#463730] hover:text-[#008080]'}`}>My Projects</Link>
          </div>
        </header>

        <main className="space-y-12">
          <Section 
            title="A Bit About Me" 
            darkMode={darkMode}
          >
            <div className="flex flex-col space-y-4">
              <p>
                Hello! I'm a (first year) PhD student at UCL, specialising in machine learning within computational chemistry, more specifically Non-Adiabtic Dynamics (NAD). I particularly enjoy method development, especially with the combination of maths and chemistry stuff. I want to learn more low-level/hardware stuff. 
              </p>
              <div className="flex justify-center">
                <pre className="text-[0.4rem] sm:text-[0.6rem] md:text-[0.8rem] leading-[0.4rem] sm:leading-[0.6rem] md:leading-[0.8rem] inline-block whitespace-pre overflow-hidden">{ASCII_ART}</pre>

              </div>
            </div>
          </Section>
        </main>

        <footer className={`mt-12 pt-4 border-t ${darkMode ? 'border-gray-500' : 'border-[#463730]/30'}`}>
          <p className={`text-sm text-center ${darkMode ? 'text-gray-500' : 'text-[#463730]/70'}`}>
            algorithms and caffeine | MEBGrad
          </p>
        </footer>
      </div>
    </div>
  );
};

export default PersonalWebsite;
